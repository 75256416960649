import { Box, Button, Card, Divider, Skeleton, TextField, Toolbar, useMediaQuery } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { AdvancedChart, CompanyProfile, TechnicalAnalysis, TickerTape } from 'react-tradingview-embed';

import theme from '../../theme';
import Stream from '../../utils/stream-chart';

const CRYPTO_COMPARE = '54c69a67adfc783963d3589c5a08a40a5d619b0f22b94b1c79df9acc9129c5ff';

const Trade = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [chartData, setChartData] = useState([]);
  const [query, setQuery] = useState('BTC');
  const [content, setContent] = useState(<SkeletonLoader />);
  const [loaded, setLoaded] = useState(false);
  const [currStep, setCurrStep] = useState(0);
  const [symbol, setSymbol] = useState('');

  const loadChartData = useCallback(async () => {
    try {
      const response = await fetch(
        `https://min-api.cryptocompare.com/data/blockchain/histo/day?fsym=${query}&api_key=${CRYPTO_COMPARE}&limit=30`
      );
      const data = await response.json();

      const bulkData = data?.Data?.Data;
      const dataArray = [];
      bulkData?.map((y) =>
        dataArray.push({
          x: y.time * 1000,
          y: y.transaction_count * y.average_transaction_value,
        })
      );
      setChartData(dataArray);
      setSymbol(query);

      setTimeout(() => {
        setLoaded(true);
      }, 2000);
    } catch (error) {
      console.log('INIT ERROR ::: ', error);
    }
  }, [query]);

  React.useEffect(() => {
    loadChartData();
  }, [loadChartData]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  React.useEffect(() => {
    if (loaded) {
      setContent(
        <Box component={Box} px={matches ? 6 : 0}>
          <Card
            elevation={3}
            sx={{ boxShadow: 'revert', border: 'none' }}
            component={Box}
            bgcolor={'white'}
            p={4}
            display="flex"
            flexDirection={'column'}
            justifyContent={'start'}
          >
            <Box display="flex" flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
              <Card
                elevation={3}
                sx={{
                  border: 'none',
                  mt: -3,
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  sx={{ px: 4, bgcolor: currStep === 0 ? theme.palette.secondary.main : 'grey' }}
                  onClick={() => setCurrStep(0)}
                >
                  {'Live Trading'}
                </Button>
                <Button
                  variant="contained"
                  sx={{ px: 4, bgcolor: currStep === 1 ? theme.palette.secondary.main : 'grey', ml: -1 }}
                  onClick={() => setCurrStep(1)}
                >
                  {'Market Trends'}
                </Button>
              </Card>
            </Box>
            <Divider />
            <br />
            <div>
              <div className="inputDiv">
                {/* <input
                placeholder="Search for a symbol"
                // ref={(input) => (search = input)}
                onChange={handleInputChange}
                className="dataRequest"
              /> */}
                <TextField
                  variant="outlined"
                  placeholder="Search for a symbol"
                  onChange={handleInputChange}
                  size="small"
                  className="dataRequest"
                />
                <Button variant="contained" onClick={() => loadChartData()} className="dataRequest">
                  Load Onchain Data
                </Button>
                <TickerTape
                  widgetProps={{
                    showSymbolLogo: true,
                    isTransparent: true,
                    displayMode: 'adaptive',
                    colorTheme: 'light',
                    symbols: [
                      {
                        proName: 'BITSTAMP:ETHUSD',
                        title: 'ETH/USD',
                      },
                      {
                        proName: 'BITSTAMP:BTCUSD',
                        title: 'BTC/USD',
                      },
                      {
                        proName: 'BITSTAMP:SOLUSD',
                        title: 'SOL/USD',
                      },
                      {
                        proName: 'BINANCE:BNBUSDT',
                        title: 'BNB/USDT',
                      },
                      {
                        proName: 'BINANCE:ADAUSD',
                        title: 'ADA/USD',
                      },
                      {
                        proName: 'BINANCE:DOTUSDT',
                        title: 'DOT/USDT',
                      },
                    ],
                  }}
                />
              </div>
              <Box p={3} />
              {currStep === 0 ? (
                <Stream />
              ) : (
                <div className="charty">
                  {query.length > 2 ? (
                    <AdvancedChart
                      widgetProps={{
                        allow_symbol_change: true,
                        interval: '1D',
                        width: '100%',
                        symbol: `${query}USD`,
                        theme: 'dark',
                        withdateranges: true,
                      }}
                    />
                  ) : (
                    `${query}`
                  )}

                  <div className="taChart">
                    <div className="addressHover">
                      <Toolbar />
                    </div>

                    {/* {query.length > 2 ? (
                  <TechnicalAnalysis
                    widgetProps={{
                      interval: '1D',
                      showIntervalTabs: true,
                      colorTheme: 'dark',
                      width: '100%',
                      symbol: `${query}USD`,
                    }}
                  />
                ) : (
                  query
                )} */}

                    {/* {query.length > 2 ? (
                  <CompanyProfile
                    widgetProps={{
                      colorTheme: 'dark',
                      width: '100%',
                      height: matches ? 256 : 320,
                      symbol: `${query}USD`,
                    }}
                  />
                ) : (
                  'BTCUSD'
                )} */}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </Box>
      );
    }
  }, [currStep, loadChartData, loaded, matches, query]);

  return content;
};

const SkeletonLoader = () => (
  <Box p={4}>
    <Skeleton width={100} height={48} variant="text" />
    <br />
    <Skeleton width={'100%'} height={320} variant="rounded" />
    <br />
    <Skeleton width={'100%'} height={256} variant="rounded" />
  </Box>
);

export default Trade;
