import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import { FormHelperText } from '@mui/material';
import APIService from '../../service';

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: 10,
  marginTop: 10,
}));

const bankSchema = Yup.object().shape({
  cryptocurrency: Yup.string().required('Crypto-currency is required'),
  walletAddress: Yup.string().required('Wallet address is required'),
  name: Yup.string().required('Crypto name is required'),
});

function WalletForm(props) {
  const { mutate, loading, setLoading, setOpenModal } = props;
  const { cryptos } = useSelector((state) => state.crypto);
  const { profile } = useSelector((state) => state.auth);


  const formik = useFormik({
    initialValues: {
      cryptocurrency: '',
      walletAddress: '',
      name: '',
    },
    validationSchema: bankSchema,
    onSubmit: async () => {
      setLoading(true);
      const payload = {
        user: profile?.id ?? profile?._id,
        cryptocurrency: values.cryptocurrency,
        walletAddress: values.walletAddress,
        name: values.name,
      };
      
      const response = APIService.post('/crypto/wallet/create', payload);

      toast.promise(response, {
        loading: 'loading',
        success: (res) => {
          // setBank(res?.data);
          mutate('/crypto/wallet/all');
          setLoading(false);
          setOpenModal(false);
          return 'Wallet Added Successfully!';
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || err?.message || 'Something went wrong, try again.';
        },
      });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box p={2}>
          {cryptos && (
            <FormControl fullWidth error={Boolean(touched.cryptocurrency && errors.cryptocurrency)}>
              <InputLabel htmlFor="cryptocurrency" sx={{ bgcolor: 'background.paper' }}>
                <em>Select cryptocurrency</em>
              </InputLabel>
              <NativeSelect
                input={
                  <OutlinedInput
                    variant="outlined"
                    value={values.cryptocurrency}
                    onChange={(e) => {
                      setFieldValue('cryptocurrency', e.target.value);
                      const filtered = cryptos ?? [].filter((item) => item.id === e.target.value);
                      setFieldValue('name', filtered[0]?.name);
                    }}
                    id="cryptocurrency"
                  />
                }
                id="cryptocurrency"
              >
                <option>Select cryptocurrency</option>
                {cryptos?.map((elem) => (
                  <option key={elem?.id ?? elem?._id} value={elem?.id ?? elem?._id}>
                    {elem?.name}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>{touched.cryptocurrency && errors.cryptocurrency}</FormHelperText>
            </FormControl>
          )}

          <StyledTextField
            fullWidth
            label="Wallet Address"
            {...getFieldProps('walletAddress')}
            error={Boolean(touched.walletAddress && errors.walletAddress)}
            helperText={touched.walletAddress && errors.walletAddress}
          />

          <Box p={1} />
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            {'Add Wallet'}
          </LoadingButton>
          <Toaster />
          <Box p={2} />
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default WalletForm;
